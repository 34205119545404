import { storyData } from '../building-future/climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import WedoIco35 from '@/assets/img/nodiscrimination/education_obj1_4@2x.png';
import CardImg1 from '@/assets/img/nodiscrimination/fairchance_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/nodiscrimination/fairchance_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/nodiscrimination/fairchance_card_img3@2x.jpg';
import CardImg4 from '@/assets/img/nodiscrimination/fairchance_card_img4@2x.jpg';
import WedoIco1 from '@/assets/img/nodiscrimination/fairchance_obj1_1@2x.png';
import WedoIco2 from '@/assets/img/nodiscrimination/fairchance_obj1_2@2x.png';
import WedoIco31 from '@/assets/img/nodiscrimination/fairchance_obj2020_1@2x.png';
import WedoIco32 from '@/assets/img/nodiscrimination/fairchance_obj2020_2@2x.png';
import WedoIco33 from '@/assets/img/nodiscrimination/fairchance_obj2020_3@2x.png';
import WedoIco3 from '@/assets/img/nodiscrimination/fairchance_obj2_2@2x.png';
import WedoIco4 from '@/assets/img/nodiscrimination/fairchance_obj2_3@2x.png';
import FairChanceTargetObj from '@/assets/img/nodiscrimination/fairchance_sec2_obj2@2x.png';
import FairChanceTargetObjM from '@/assets/img/nodiscrimination/fairchance_sec2_obj2M@2x.png';
import AccordionComponent, { AccordionItem } from '@/components//Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTarget,
  SectionTop,
  SectionWedo,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `어린이 빈곤`,
    dec: `빈곤은 어린이가 자신의 잠재력을 발휘할 수 있는 기회를 놓치게 만들 뿐 아니라 아동권리 모든 범위에서 심각한 영향을 미칩니다. 미래 세대와 사회에도 영향을 미칩니다. 빈곤의 고리를 끊기 위해서는 어린이와 가족에 대한 조기 개입과 투자가 필요합니다. 유니세프는 어린이 빈곤에 대한 보편적인 측정으로 국가 정책과 프로그램에 적용할 수 있도록 지원합니다. 정책 분석 및 참여로 어린이 빈곤 퇴치를 위한 정책을 지원합니다. 국가 발전 계획 및 빈곤 감소 전략에 어린이 빈곤을 최우선으로 고려할 수 있도록 합니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `어린이를 위한 재정`,
    dec: `정부의 효과적인 정책, 입법, 프로그램을 통해 재정을 사용해야 아동권리를 실현할 수 있습니다. 유니세프는 아동권리 관점에서 정부가 재정 계획을 세우고 어린이를 위한 재정 할당과 지출의 투명성을 높여 실질적인 변화를 이끌어내는 데 협력합니다. 교육·보건·보호·식수와 위생 등 어린이에게 중요한 부문과 서비스에 충분한 재정을 할당하도록 지원합니다. 또한 재정을 합리적으로 사용하고 서비스 품질에 대한 저하 없이 최저 비용으로 프로그램을 지원하며, 할당된 재정이 제시간에 예정된 사용자에게 도달할 수 있도록 사용 효율성을 높이기 위해 지원합니다. 또한 재정이 어린이에게 가장 중요한 서비스와 프로그램에 쓰이도록 협력하고, 어린이와 시민이 재정 계획 및 지출 방식에 의견을 낼 수 있도록 지원합니다.`,
    img: CardImg2,
  },
  {
    id: 3,
    title: `사회 보호`,
    dec: `최근 코로나19 팬데믹을 비롯해 인도주의 위기 상황이 늘어남에 따라 사회 보호의 중요성이 더욱 강조되고 있습니다. 유니세프는 어린이에 대한 포괄적이고 질 높은 사회 보호 시스템 확대를 지원합니다. 어린이 빈곤 분석, 영향 평가, 시스템 평가를 비롯해 정책 및 전략 개발과 자금 조달을 지원합니다. 또한 위기 대응 사회 보호 시스템을 강화할 수 있도록 협력하고, 어린이를 위한 현금 이전, 건강보험 확대 및 개선, 성별 및 장애 여부에 따른 사회 보호 체계 강화를 중점으로 지원합니다.`,
    img: CardImg3,
  },
  {
    id: 4,
    title: `어린이와 시민 참여`,
    dec: `유니세프는 전 세계 어린이가 권리 주체자이자 의무 이행자로서 아동권리를 이해하고 누릴 수 있도록 돕고, 어린이와 관련된 의사 결정 능력을 키우는 프로그램을 지원합니다. 어린이 인플루언서와 협력해 어린이 관련 문제에 대한 인식도 증진합니다. 유니세프는 또한 성별, 장애, 민족성, 기타 지위에 관계없이 모든 어린이에게 동등한 기회를 제공합니다. 어린이 관련 정책을 결정하는 과정에 어린이의 참여를 촉진하고 이를 제도화하는데 힘씁니다.`,
    img: CardImg4,
  },
];

const wedo = [  
  {
    id: 1,
    tit: `2019년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco1,
        desc: [
          {
            dec: `빈곤 및 소외 계층 어린이 \n<strong>5,100만 명</strong> 이상에게 현금 지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco3,
        desc: [
          {
            dec: `청소년 <strong>400만 명</strong>에게 \n유니세프가 지원하는 프로그램에 참여 기회 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco4,
        desc: [
          {
            dec: `<strong>97개국</strong>과 협력해 어린이 빈곤 상황 모니터링 및 정책 개발 촉구`,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    tit: `2020년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `<strong>31개국</strong>과 협력해 어린이 빈곤 상황 모니터링 및 정책 개발 촉구`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco32,
        desc: [
          {
            dec: `빈곤 및 소외 계층 어린이 <strong>93개국 1억 3천만 명</strong> 이상에게 현금 지원`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco33,
        desc: [
          {
            dec: `<strong>122개국 720만 명</strong> 청소년이 유니세프가 지원하는 프로그램에게 참여 기회 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    tit: `2021년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `<strong>78개국</strong>에 어린이 빈곤 측정을 위한 시스템 구축 지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco2,
        desc: [
          {
            dec: `<strong>32개국</strong>과 협력하여 어린이 빈곤을 줄이는 정책과 프로그램 수립`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco32,
        desc: [
          {
            dec: `<strong>95개국</strong> 취약계층 어린이 및 가정 <strong>1억 3,300만 명</strong>에게 현금 지원`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco35,
        desc: [
          {
            dec: `<strong>148개국</strong> 장애 어린이 <strong>480만 명</strong> 지원`,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    tit: `2022년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `<strong>60개국</strong>에 장애 어린이 통합 사회보호 서비스 도입`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco32,
        desc: [
          {
            dec: `취약계층 어린이 및 가족 <strong>1억 2,900만 명</strong>에게 현금 지원`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco2,
        desc: [
          {
            dec: `난민 어린이 및 가정을 위한 <br />통합 사회보호 서비스 도입`,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    tit: `2023년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `평균 이상의 사회보호제도를 갖춘 국가가 <strong>79개국</strong>으로 증가<br/>(2021년 56개국)`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco32,
        desc: [
          {
            dec: `인도적 위기 대응체계를 갖춘 국가가 <strong>22개국</strong>으로 증가<br/>(2021년 17개국)`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco2,
        desc: [
          {
            dec: `<strong>1억 600만 가구 이상</strong>에게<br/>유니세프 현금지원`,
          },
        ],
      },
    ],
  },
];

const NavigationButton = styled.div``;
const H2 = styled.h2`
  ${breakpoint(443)} {
    br {
      display: none;
    }
  }
`;

const FairChance: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST05'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A007'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="공평한 기회"
      description="for every child, a fair chance"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 자신의 잠재력을 마음껏{` `}
                  <br className="small-hide" />
                  실현할 권리가 있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                유니세프는 모든 어린이가 공평한 기회를 누리고 각자가 자신의
                잠재력을 최대한 발휘할 수 있도록 지원합니다. 또한 사회적
                불평등을 해소하고, 공평한 기회를 나누기 위해 노력합니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="jLkU6P8Y03A" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionTarget className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <div className="col-header">
              <h2>
                <Tit size="s1">
                  2022-2025 <br />
                  유니세프 중점 사업 목표
                </Tit>
              </h2>
              <ul className="blit">
                <li>
                  사회 보호 시스템이 적용되는 전 세계 어린이{' '}
                  <strong>증가</strong>
                </li>
                <li>
                  절대 빈곤 속에 살아가는 어린이 <strong>감소</strong>
                </li>
              </ul>
            </div>
            <Image
              pcSrc={FairChanceTargetObj}
              mobileSrc={FairChanceTargetObjM}
            />
          </SectionHeader>
        </Container>
      </SectionTarget>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <H2>
              <Tit size="s1">
                모든 어린이의 공평한 기회를 위해,
                <br className="m-hide" /> 전 세계 파트너와 함께 유니세프가 한 일
              </Tit>
            </H2>
          </SectionHeader>

          <AccordionComponent initialKey={wedo[wedo.length - 1].id}>
            {wedo.map((row) => (
              <AccordionItem
                key={row.id}
                toggleKey={row.id}
                renderToggle={(onClick) => (
                  <NavigationButton
                    onClick={onClick}
                    className="accordion-opener"
                  >
                    {row.tit}
                  </NavigationButton>
                )}
              >
                <div className="accordion-body">
                  <ProcessList itemData={row.child} />
                </div>
              </AccordionItem>
            ))}
          </AccordionComponent>
        </Container>
      </SectionWedo>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation onPaginationHide>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A007" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default FairChance;
